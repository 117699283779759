<template>
  <div class="bg-white container">
    <div v-if="reg.cashregister">
      <div class="row ">
        <div class="col mt-2">
          <h4>CAJA REGISTRADORA N° {{ cashRegisterId }}</h4>
          <h5 class="text-muted">Area: {{ reg.cashregister.area_name }}</h5>
          <h5 v-if="config.set_initial_cash" class="text-muted">
            Monto Inicial:
            <app-span-money
              :quantity="reg.cashregister.initial_cash"
              :moneyCode="reg.cashregister.money_code"
            ></app-span-money>
          </h5>
          <h5 class="text-muted">
            Responsable: {{ reg.cashregister.r_user_name }}
          </h5>
          <h5 class="text-muted">
            Fecha: {{ reg.cashregister.created_at | dateFormat }}
          </h5>
        </div>
      </div>
      <div class="row no-print">
        <div class="col">
          <div v-if="!locked">
            <button
              class="btn btn-primary mr-lg-2"
              :class="{ 'btn-block': $store.state.display.sm }"
              @click="
                $refs.dFormInput.show();
                $refs.formInput.reset();
              "
            >
              <i class="fa fa-sign-in-alt"></i> Registrar Ingreso
            </button>
            <button
              class="btn btn-primary mr-lg-2"
              :class="{ 'btn-block': $store.state.display.sm }"
              @click="
                $refs.dFormOutput.show();
                $refs.formOutput.reset();
              "
            >
              <i class="fa fa-sign-out-alt"></i> Registrar Egreso
            </button>
            <button
              class="btn btn-primary mr-lg-2"
              :class="{ 'btn-block': $store.state.display.sm }"
              @click="$refs.dCerrarCaja.show()"
            >
              <i class="fa fa-lock"></i> Cerrar Caja
            </button>
          </div>
          <div v-else>
            <button
              class="btn btn-primary mr-lg-2"
              :class="{ 'btn-block': $store.state.display.sm }"
              @click="sendToPrint()"
            >
              <i class="fa fa-print"></i> Imprimir
            </button>
            <slot name="lockedButtons"></slot>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col" v-if="reg.input_resume && reg.input_resume.length > 0">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>FUENTE DE INGRESO</th>
                <th class="text-right">SUBTOTAL</th>
              </tr>
            </thead>
            <tbody>
              <template>
                <tr
                  v-for="l in reg.input_resume"
                  :key="l.source_name + l.money_code"
                >
                  <td>{{ l.source_name }}</td>
                  <td class="text-right">
                    <app-span-money
                      :quantity="l.quantity"
                      :moneyCode="l.money_code"
                    ></app-span-money>
                  </td>
                </tr>
                <tr v-for="(l, index) in reg.input_total" :key="index">
                  <th>TOTAL</th>
                  <th class="text-right">
                    <app-span-money
                      :quantity="l"
                      :moneyCode="index"
                    ></app-span-money>
                  </th>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div
          class="col"
          v-if="reg.output_resume && reg.output_resume.length > 0"
        >
          <table class="table table-sm">
            <thead>
              <tr>
                <th>RAZÓN DE EGRESO</th>
                <th class="text-right">SUBTOTAL</th>
              </tr>
            </thead>
            <tbody>
              <template>
                <tr
                  v-for="l in reg.output_resume"
                  :key="l.reason_name + l.money_code"
                >
                  <td>{{ l.reason_name }}</td>
                  <td class="text-right">
                    <app-span-money
                      :quantity="l.quantity"
                      :moneyCode="l.money_code"
                    ></app-span-money>
                  </td>
                </tr>
                <tr v-for="(l, index) in reg.output_total" :key="index">
                  <th>TOTAL</th>
                  <th class="text-right">
                    <app-span-money
                      :quantity="l"
                      :moneyCode="index"
                    ></app-span-money>
                  </th>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div :class="!printDetails ? 'no-print' : ''">
        <hr />
        <div class="row">
          <div class="col">
            <app-checkbox
              id="inputPrintDet"
              class="no-print"
              v-model="printDetails"
              placeholder="Imprimir esta seccion"
            ></app-checkbox>
            <h5>INGRESOS</h5>
            <TableInputs
              :inputs="reg.inputs"
              :readonly="locked"
              @itemDeleted="getCashRegister()"
            ></TableInputs>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h5>EGRESOS</h5>
            <TableOutputs :outputs="reg.outputs">
              <th slot="thead" v-if="!locked" class="no-print"></th>
              <td
                slot="tbody"
                v-if="!locked"
                slot-scope="props"
                class="no-print btn-group"
              >
                <button
                  v-if="config.show_btn_change_reason_output"
                  class="btn btn-sm btn-light"
                  title="Cambiar razón de egreso"
                  @click="
                    $refs.dChangeReason.show();
                    $refs.formChangeReasonOutput.loadReg(props.reg);
                  "
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  v-if="config.show_btn_delete_output"
                  class="btn btn-light btn-sm"
                  @click="
                    $refs.dDeleteOut.show();
                    $refs.dDeleteOut.val = props.reg;
                  "
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </TableOutputs>
          </div>
        </div>
      </div>
      <div class="row" :class="!printAdds ? 'no-print' : ''">
        <hr />
        <div class="col">
          <div class="form-group no-print">
            <app-checkbox
              id="cbPrintAdds"
              v-model="printAdds"
              placeholder="Imprimir esta seccion"
            ></app-checkbox>
          </div>
          <template v-if="reg.totals">
            <h5 class="text-right">INGRESOS TOTALES</h5>
            <div
              class="form-group row"
              v-for="l in reg.totals.inputs"
              :key="'totals' + l.money_code + l.payment_method_id"
            >
              <label class="col col-form-label text-right">{{
                getPayMethod(l.payment_method_id)
              }}</label>
              <div class="col">
                <p class="border rounded p-1 pr-2 text-right">
                  <app-span-money
                    :moneyCode="l.money_code"
                    :quantity="l.quantity"
                  ></app-span-money>
                </p>
              </div>
            </div>
          </template>
          <div
            class="row"
            v-if="$store.state.config.admin && locked"
            v-show="$store.state.config.admin.cashregisters.sign_locked"
          >
            <div class="col text-center">
              <hr class="linea-firma" />
              <label class>Firma Supervisor</label>
            </div>
            <div class="col text-center">
              <hr class="linea-firma" />
              <label class>Firma Responsable</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-modal-basic ref="dFormInput">
      <h4>REGISTRAR INGRESO</h4>
      <FormInput
        ref="formInput"
        :cashRegisterId="cashRegisterId"
        @submitted="
          $refs.dFormInput.hide();
          getCashRegister();
        "
      ></FormInput>
    </app-modal-basic>

    <app-modal-basic ref="dFormOutput">
      <h4>REGISTRAR EGRESO</h4>
      <FormOutput
        ref="formOutput"
        :cashRegisterId="cashRegisterId"
        @submitted="
          $refs.dFormOutput.hide();
          getCashRegister();
        "
      ></FormOutput>
    </app-modal-basic>

    <app-modal-basic ref="dChangeReason">
      <h4>CAMBIAR RAZON DE EGRESO</h4>
      <FormChangeReasonOutput
        :cashRegisterId="cashRegisterId"
        @submitted="
          $refs.dChangeReason.hide();
          getCashRegister();
        "
        ref="formChangeReasonOutput"
      ></FormChangeReasonOutput>
    </app-modal-basic>

    <app-modal-basic idModal="dCerrarCaja" ref="dCerrarCaja">
      <p>¿Seguro de cerrar esta caja?</p>
      <div>
        <button
          class="btn btn-secondary right-space"
          @click="$refs.dCerrarCaja.hide()"
        >
          Cancelar
        </button>
        <button class="btn btn-primary" @click="closeBox()">Si</button>
      </div>
    </app-modal-basic>

    <app-modal-yn
      ref="dDeleteOut"
      @yes="deleteOutput($refs.dDeleteOut.val.id)"
      @no="$refs.dDeleteOut.hide()"
    >
      <p v-if="$refs.dDeleteOut && $refs.dDeleteOut.val">
        Seguro de eliminar el egreso
        <strong>{{
          $refs.dDeleteOut ? $refs.dDeleteOut.val.observation : ""
        }}</strong>
        <br />Por
        <app-span-money
          :quantity="$refs.dDeleteOut.val.quantity"
          :moneyCode="$refs.dDeleteOut.val.money_code"
        ></app-span-money>
      </p>
    </app-modal-yn>
  </div>
</template>

<script>
// import { myMoment } from "../../utils/myMoment";
import { AdminService } from "../AdminService";
import FormInput from "../inputs/Form.vue";
import FormOutput from "../outputs/Form.vue";
import TableInputs from "./TableInputs";
import TableOutputs from "./TableOutputs";
import FormChangeReasonOutput from "./FormChangeReasonOutput";

export default {
  components: {
    FormInput,
    FormOutput,
    TableInputs,
    TableOutputs,
    FormChangeReasonOutput
  },
  props: {
    cashRegisterId: {
      required: true
    }
  },
  data() {
    return {
      reg: {},
      printDetails: false,
      printAdds: true
    };
  },
  methods: {
    deleteOutput(id) {
      AdminService.deleteCROutput(this.cashRegisterId, id).then(() => {
        this.getCashRegister();
        this.$refs.dDeleteOut.hide();
      });
    },
    sendToPrint() {
      window.print();
    },
    closeBox() {
      AdminService.saveCashRegister({
        id: this.cashRegisterId,
        lock: 1
      }).then(() => {
        this.getCashRegister();
        this.$refs.dCerrarCaja.hide();
      });
    },
    getCashRegister() {
      AdminService.getCashRegister(this.cashRegisterId).then(reg => {
        this.reg = reg;
      });
    },
    getPayMethod(id) {
      return this.$store.state.config.payment_method.list[id] ? this.$store.state.config.payment_method.list[id].value : "";
    }
  },
  watch: {
    cashRegisterId: {
      immediate: true,
      handler() {
        this.getCashRegister();
      }
    }
  },
  computed: {
    locked() {
      if (this.reg.cashregister) {
        return this.reg.cashregister.locked == 1 ? true : false;
      }
      return true;
    },
    config() {
      return this.$store.state.config.admin.cashregisters;
    }
  },
  mounted() {
    //
  }
};
</script>

<style>
.linea-firma {
  margin-top: 70px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
